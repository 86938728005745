const select = () => {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: '6rem !important',
          '&.MuiTablePagination-select': {
            minWidth: '1.5rem !important'
          },
          textOverflow: 'initial',
          whiteSpace: 'initial',
          overflow: 'initial'
        }
      }
    }
  }
}

export default select
