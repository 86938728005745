// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

// ** Source code Imorts
import { AttachType } from 'src/types/apps/chatTypes'

interface IProps {
  attaches: AttachType[]
  onViewerItemOpen: (state: string | null) => void
}

const MsgAttaches = ({ attaches, onViewerItemOpen }: IProps) => {
  const renderAttach = ({ _id, type, attach, name }: AttachType, index: number) => {
    if (type === 'image') return (
      <Box key={index} sx={{ pt: 4 }}>
        <Box
          sx={{ width: 1, cursor: 'pointer' }}
          component='img'
          src={attach}
          onClick={() => onViewerItemOpen(_id)}
        />
        <Typography variant='body3' component='a' href={attach} target='_blank'>
          {name}
        </Typography>
      </Box>
    )

    return (
      <Typography
        key={index}
        sx={{ pt: 4, display: 'flex', alignItems: 'center' }}
        component='a'
        href={attach}
        target='_blank'
      >
        <InsertDriveFileIcon sx={{ mr: 2, fontSize: 48 }} />
        <Typography variant='body2'>{name}</Typography>
      </Typography>
    )
  }

  return <Box>{attaches.map(renderAttach)}</Box>
}

export default MsgAttaches
