// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Defaults
const defaultProvider: {
  history: string[]
  setHistory?: any
} = {
  history: []
}

const HistoryContext = createContext<{
  history: string[]
  setHistory?: any
}>(defaultProvider)

type Props = {
  children: ReactNode
}

const HistoryProvider = ({ children }: Props) => {
  // ** States
  const [history, setHistory] = useState<string[]>([])

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const isValidPath = !router.asPath.match(/\[(.*?)\]/)

    if (isValidPath) setHistory(state => [...state, router.asPath])
  }, [router])

  return <HistoryContext.Provider value={{ setHistory, history }}>{children}</HistoryContext.Provider>
}

export { HistoryContext, HistoryProvider }
