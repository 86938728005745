import axios from 'axios'
import { createStore, createEffect, createEvent } from 'effector'

// ** Config
import releasesConfig from 'src/configs/releases'
import newsConfig from 'src/configs/news'
import faqsConfig from 'src/configs/faq'

const getSearchReleases = createEffect(async ({ page, limit, query }: any) => {
  const { data } = await axios.post(releasesConfig.getReleasesList(), {
    page,
    limit,
    query
  })

  return data
})

const getSearchNews = createEffect(async (payload: Record<string, any> = {}) => {
  const { data } = await axios.post(newsConfig.getNewsList(), payload)

  return data
})

const getSearchFaqs = createEffect(async (payload: Record<string, any> = {}) => {
  const { data } = await axios.post(faqsConfig.getFaqArticlesList(), payload)

  return data
})

const resetSearch = createEvent()

const search = createStore<any>({
  releases: [],
  news: [],
  faqs: [],
  loadingReleases: true,
  loadingNews: true,
  loadingFaqs: true
})
  .on(getSearchReleases, state => {
    return {
      ...state,
      loadingReleases: true
    }
  })
  .on(getSearchReleases.doneData, (state, payload: any) => {
    return {
      ...state,
      releases: payload.data,
      loadingReleases: false
    }
  })
  .on(getSearchNews, state => {
    return {
      ...state,
      loadingNews: true
    }
  })
  .on(getSearchNews.doneData, (state, payload: any) => {
    return {
      ...state,
      news: payload.data,
      loadingNews: false
    }
  })
  .on(getSearchFaqs, state => {
    return {
      ...state,
      loadingFaqs: true
    }
  })
  .on(getSearchFaqs.doneData, (state, payload: any) => {
    return {
      ...state,
      faqs: payload.data,
      loadingFaqs: false
    }
  })
  .reset(resetSearch)

export { getSearchReleases, getSearchNews, getSearchFaqs, resetSearch }
export default { search }
