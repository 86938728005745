// ** React Imports
import { useCallback, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

// ** Hooks
import { useAuth } from 'src/hooks/useAuth'

// ** Config
import authConfig from 'src/configs/auth'
import apiConfig from 'src/configs/api'

// ** Modules Imports
import Cookies from 'js-cookie'

interface IProps {
  channel: string
  actions?: Record<string, any>
  share?: boolean
  subscribeData?: Record<string, any>
  ruleConnection?: boolean
}

interface Msg {
  channel?: any
  event?: any
  data?: any
}

export const ACTIONS = Object.freeze({
  // ** Pusher
  SUBSCRIPTION_SUCCEEDED: 'pusher_internal:subscription_succeeded',
  SUBSCRIPTION_ERROR: 'pusher_internal:subscription_error',
  UNSUBSCRIBED: 'pusher_internal:unsubscribed',
  SUBSCRIBE: 'pusher:subscribe',
  UNSUBSCRIBE: 'pusher:unsubscribe',
  ERROR: 'pusher:error',
  SIGNIN: 'pusher:signin',
  SIGNIN_SUCCESS: 'pusher:signin_success',
  CACHE_MISS: 'pusher:cache_miss',
  PING: 'pusher:ping',
  PONG: 'pusher:pong',
  CONNECTION_ESTABLISHED: 'pusher:connection_established',

  // ** Members
  ADDED_MEMBER: 'pusher_internal:member_added',
  REMOVED_MEMBER: 'pusher_internal:member_removed'
})

function useWebsocket(props: IProps) {
  const storedToken = Cookies.get(authConfig.storageTokenKeyName)!

  const { user } = useAuth()
  const { channel, share = false, subscribeData = {}, ruleConnection = true } = props
  const socketUrl = `${apiConfig.wsEndpoint}/app/${apiConfig.space}`

  const safeChannel = channel.replace(/[^\w\s]/gi, '-').replace(' ', '-')

  const [isConnectionEstablished, setIsConnectionEstablished] = useState(false)
  const [isJoined, setIsJoined] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [onlineCount, setOnlineCount] = useState(0)

  const actions: any = {
    ...props.actions,
    [ACTIONS.ERROR]: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (msg: Msg) => {
        setIsConnectionEstablished(false)
        setIsJoined(false)
        setIsSigned(false)
        setOnlineCount(0)
      },
      [setIsConnectionEstablished]
    ),
    [ACTIONS.CONNECTION_ESTABLISHED]: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (msg: Msg) => {
        setIsConnectionEstablished(true)
      },
      [setIsConnectionEstablished]
    ),
    [ACTIONS.SUBSCRIPTION_SUCCEEDED]: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (msg: Msg) => {
        setIsJoined(true)
      },
      [setIsJoined]
    ),
    [ACTIONS.UNSUBSCRIBED]: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (msg: Msg) => {
        setIsJoined(false)
      },
      [setIsJoined]
    ),
    [ACTIONS.SIGNIN_SUCCESS]: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (msg: Msg) => {
        setIsSigned(true)
      },
      [setIsSigned]
    )
  }

  const { readyState, sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen: () => {},
      onMessage: event => {
        const msg = JSON.parse(event.data)

        if (actions[msg.event]) {
          actions[msg.event](msg)
        }
      },
      reconnectInterval: 15000,
      reconnectAttempts: 200,
      shouldReconnect: () => true,
      retryOnError: true,
      share
    },
    !!apiConfig.space && !!ruleConnection
  )

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !isConnectionEstablished) {
      return
    }

    if (!storedToken || !user || isSigned) {
      return
    }

    sendJsonMessage({
      event: ACTIONS.SIGNIN,
      data: {
        auth: storedToken,
        user_data: JSON.stringify(user)
      }
    })
  }, [readyState, isConnectionEstablished, user, storedToken, isSigned, sendJsonMessage])

  /**
   * Подключаемся к каналу
   */
  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !isConnectionEstablished || isJoined) {
      return
    }

    if (storedToken && !isSigned) return

    sendJsonMessage({
      event: ACTIONS.SUBSCRIBE,
      data: {
        channel: safeChannel,
        auth: storedToken,
        user_data: JSON.stringify(user),
        ...subscribeData
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendJsonMessage, readyState, isJoined, isConnectionEstablished, storedToken, user, isSigned])

  /**
   * Пигнуем сервер о том, что мы еще активны в канале
   */
  useEffect(() => {
    let timeout: any

    const run = () => {
      timeout = setTimeout(() => {
        sendJsonMessage({
          event: ACTIONS.PING,
          channel: safeChannel
        })
        run()
      }, 15000)
    }

    if (isJoined) {
      run()
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [sendJsonMessage, isJoined, safeChannel])

  return { isConnectionEstablished, isJoined, onlineCount }
}

export default useWebsocket
