// ** React Imports
import { useEffect } from 'react'

// ** Modules Imports
import axios from 'axios'

// ** Hooks Imports
import { useAuth } from 'src/hooks/useAuth'

// ** Configs Imports
import apiConfig from 'src/configs/api'

const useDocumentsExistChecker = () => {
  const auth = useAuth()

  const handleCheckDocuments = async () => {
    await axios.get(`${apiConfig.apiEndpoint}/api/silk/webhook/docs_checker`)
    localStorage.setItem('lastCheckDocumentsExistDate', new Date().toString())
  }

  useEffect(() => {
    if (auth.user === null) return

    const lastCheckDocumentsExistDate = localStorage.getItem('lastCheckDocumentsExistDate')!

    if (!lastCheckDocumentsExistDate || !Date.parse(lastCheckDocumentsExistDate)) {
      localStorage.setItem('lastCheckDocumentsExistDate', new Date().toString())

      return
    }

    const lastCheckDate = new Date(lastCheckDocumentsExistDate)
    const millisecondsInDay = 24 * 60 * 60 * 1000
    const daysDifference = Math.floor((Date.now() - lastCheckDate.getTime()) / millisecondsInDay)

    if (daysDifference >= 2) handleCheckDocuments()
  }, [auth.user])
}

export default useDocumentsExistChecker
