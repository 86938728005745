// ** Mui Imports
import { Box, Button, Container, Link } from '@mui/material'

//** React Imports
import { useEffect, useState } from 'react'

// ** Modules Imports
import { useIntl } from 'react-intl'
import Cookies from 'js-cookie'

const CookiesPolicy = () => {
  const { formatMessage } = useIntl()

  const [isCookieConfirm, setCookieConfirm] = useState<boolean>(true)

  const onButtonHandler = () => {
    Cookies.set('coockieConfirmed', 'true', { expires: 365 })
    setCookieConfirm(true)
  }

  useEffect(() => {
    const cookieConfirm = Cookies.get('coockieConfirmed')
    setCookieConfirm(!!cookieConfirm)
  }, [])

  if (isCookieConfirm) return null

  return (
    <Box
      sx={{
        background: '#3A3E5B',
        width: '100%',
        padding: '15px 0',
        position: 'fixed',
        bottom: 0,
        zIndex: 9999
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '&.MuiContainer-root': { maxWidth: '1200px', padding: '0 0.75rem' },
          '@media (max-width:768px)': {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          sx={{
            maxWidth: '850px',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#fff',
            '@media (max-width:1024px)': {
              maxWidth: '720px'
            },
            '@media (max-width:768px)': {
              fontSize: '12px',
              mb: '16px'
            }
          }}
        >
          {formatMessage({ id: 'cookie-text-1' })}
          <Link href='/cookies-policy' target='_blank'>
            {formatMessage({ id: 'cookie-link' })}
          </Link>
          {formatMessage({ id: 'cookie-text-2' })}
        </Box>
        <Button
          onClick={onButtonHandler}
          sx={{
            width: 'fit-content',
            height: 'fit-content',
            background: '#9000FF',
            padding: '8px 26px',
            fontSize: '15px',
            fontWeight: '500',
            color: '#fff',
            textTransform: 'capitalize',
            '@media (max-width:768px)': {
              margin: 'auto'
            }
          }}
        >
          {formatMessage({ id: 'accept' })}
        </Button>
      </Container>
    </Box>
  )
}

export default CookiesPolicy
