// ** React
import { useCallback, useEffect, useRef, useState } from 'react'


// ** Modules Imports
import { useStore } from 'effector-react'

// ** Source code Imports
import chatStore, {
  clearMessages,
  createMessage,
  getMessages,
  removeMessage,
  upsertUserChannel
} from 'src/state-manager/chat'
import useWebsocket from './useWebsocket'

export const ACTIONS = Object.freeze({
  UPSERT_MESSAGE: 'pusher_internal:upsert_message',
  REMOVE_MESSAGE: 'pusher_internal:remove_message'
})

interface IProps {
  id: string | undefined
  typeChannel: string
  limit?: number
  ruleConnection?: boolean
}

function useChat({ id, typeChannel, limit = 100 }: IProps) {
  const chatId = `${typeChannel}-${id}`

  const { channel, messages, count } = useStore(chatStore.chat)

  const [page, setPage] = useState<number>(0)
  const [isDisabledPagination, setIsDisabledPagination] = useState(false)

  const onNextPage = () => setPage(page + 1)

  const shortPolling: any = useRef()

  useEffect(() => {
    setIsDisabledPagination(count <= (page + 1) * limit)
  }, [count, page, limit])

  useWebsocket({
    subscribeData: {
      chatId
    },
    channel: `chat-${chatId}`,
    actions: {
      [ACTIONS.UPSERT_MESSAGE]: useCallback((msg: any) => {
        setTimeout(() => createMessage({ ...msg, limit }), 100)
      }, []),
      [ACTIONS.REMOVE_MESSAGE]: useCallback((msg: any) => {
        setTimeout(() => removeMessage(msg), 100)
      }, [])
    },
    ruleConnection: !!id && !!chatId && !!channel
  })

  useEffect(() => {
    if (typeChannel === 'staff-to-user') upsertUserChannel({ type: typeChannel })
    clearMessages()
  }, [typeChannel])

  useEffect(() => {
    if (chatId) {
      getMessages({
        id: chatId,
        limit,
        page
      })
    }
  }, [chatId, limit, page])

  useEffect(() => {
    clearTimeout(shortPolling.current)

    shortPolling.current = setInterval(() => {
      getMessages({
        id: chatId,
        limit,
        merge: 'after'
      })
    }, 30 * 1000)

    return () => {
      clearTimeout(shortPolling.current)
    }
  }, [chatId, limit, messages])

  return { page, count, messages, isDisabledPagination, onNextPage }
}

export default useChat
