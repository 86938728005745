import axios from 'axios'
import { createStore, createEffect } from 'effector'
import { formatTimeToSeconds } from 'src/tools/time'

// ** Config
import releasesConfig from 'src/configs/releases'

const getReleasesList = createEffect(async ({ page, limit, query }: any) => {
  const { data } = await axios.post(releasesConfig.getReleasesList(), {
    page,
    limit,
    query
  })

  return data
})

const getReleasesListCount = createEffect(async () => {
  const {
    data: { count: draftCount }
  } = await axios.post(releasesConfig.getReleasesListCount(), {
    query: {
      $or: [
        { [`data.status`]: { $eq: 'new' } },
        { [`data.status`]: { $eq: 'demo' } }
      ]
    }
  })

  const {
    data: { count: changeRequiredCount }
  } = await axios.post(releasesConfig.getReleasesListCount(), {
    query: {
      [`data.status`]: { $eq: 'changes-required' }
    }
  })

  const {
    data: { count: moderationCount }
  } = await axios.post(releasesConfig.getReleasesListCount(), {
    query: {
      [`data.status`]: { $eq: 'moderation' }
    }
  })

  const {
    data: { count: releasedCount }
  } = await axios.post(releasesConfig.getReleasesListCount(), {
    query: {
      [`data.status`]: { $eq: 'released' }
    }
  })

  return {
    releasedCount,
    draftCount,
    changeRequiredCount,
    moderationCount
  }
})

const upsertRelease = createEffect(async ({ data: source }: any) => {
  const { _id, name, personsAndRoles, ...releaseData } = source

  const payload: Record<string, any> = {
    data: {
      ...releaseData,
      name: name.trim(),
      personsAndRoles: personsAndRoles.map((item: any) => ({
        ...item,
        person: item.person.trim()
      })),
      tracks: releaseData.tracks.map((row: any) => {
        const isFocusTrack = (
          releaseData.releaseType === 'single' ||
          releaseData.releaseKind === 'audio-book'
        ) ? false : row.isFocusTrack

        return {
        ...row,
          name: row.name.trim(),
          personsAndRoles: row.personsAndRoles.map((item: any) => ({
            ...item,
            person: item.person.trim()
          })),
          tiktokStartAt: formatTimeToSeconds(row.tiktokStartAt, 3),
          tiktokEndAt: formatTimeToSeconds(row.tiktokEndAt, 3),
          timeStartPreview: formatTimeToSeconds(row.timeStartPreview, 3),
          isFocusTrack
        }
      })
    },
    notice: 'automated generate silk'
  }
  if (_id) payload._id = _id

  const { data } = await axios.put(releasesConfig.upsertRelease(), payload, {})

  if (!data?.data?.success) throw new Error('Bad request: 400')

  return data?.data
})

const addRelease = createEffect((payload: any) => payload)

const removeRelease = createEffect(async (_id: string) => {
  await axios.delete(releasesConfig.removeRelease(), {
    data: { _id }
  })

  return _id
})

const releases = createStore<any>({
  data: [],
  count: 0,
  pages: 0,
  loading: true,
  releasedCount: null,
  draftCount: null,
  changeRequiredCount: null,
  moderationCount: null
})
  .on(getReleasesList, state => {
    return {
      ...state,
      loading: true
    }
  })
  .on(getReleasesList.doneData, (state, payload: any) => {
    const data = payload.data.map((item: any) => {
      const tracks = item.data.tracks.sort((a: any, b: any) => a?.data?.order - b?.data?.order)

      return {
        ...item,
        data: { ...item.data, tracks }
      }
    })

    return {
      ...state,
      data,
      loading: false,
      count: payload.count,
      pages: payload.pages
    }
  })
  .on(getReleasesListCount.doneData, (state, payload: any) => {
    return {
      ...state,
      ...payload
    }
  })
  .on(addRelease.doneData, (state, payload: any) => {
    const updatedState: any = {
      data: [...state.data, payload],
      count: state.count + 1
    }

    const type: any = {
      new: 'draftCount',
      moderation: 'moderationCount',
      'changes-required': 'changeRequiredCount',
      released: 'releasedCount'
    }[payload.data.status as string]

    if (type) updatedState[type] = state[type] + 1

    return {
      ...state,
      ...updatedState
    }
  })
  .on(removeRelease.doneData, (state, payload: any) => {
    return {
      ...state,
      data: state.data.filter(({ _id }: any) => _id !== payload),
      count: state.count - 1
    }
  })

export { getReleasesList, getReleasesListCount, upsertRelease, addRelease, removeRelease }
export default { releases }
