import { createStore, createEvent } from 'effector'

type AlertType = {
  id?: string
  autoHideDuration: number
  action?: boolean
  open: boolean
  message: {
    type: 'formatMessage' | 'custom'
    value: string
    opts?: Record<string, any>
  }
  disableTranslation?: true
  variant?: 'filled' | 'outlined' | 'standard' | undefined
  alertSeverity: 'error' | 'success'
  actionButton?: boolean
}

const createAlert = createEvent<AlertType>('createAlert')
const closeAlert = createEvent<any>('closeAlert')

const snackbar = createStore<{ alerts: (AlertType & { id: string })[] }>({
  alerts: []
})
  .on(createAlert, (state, newState: AlertType) => {
    const id = (Math.random() + 1).toString(36).substring(10)
    const data = { ...newState, id }

    let alerts = [data, ...state.alerts.filter(item => item.open)]

    if (alerts.length > 3) alerts = alerts.slice(0, 3)

    return { ...state, alerts }
  })
  .on(closeAlert, (state, id: string) => {
    const alerts = state.alerts.map(item => {
      let open = item.open

      if (item.id === id) open = false

      return { ...item, open }
    })

    return { ...state, alerts }
  })

export { createAlert, closeAlert }
export default { snackbar }
