// ** React Imports
import { ReactNode } from 'react'

// ** MUI core Imports
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme, Theme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

interface IProps {
  open: boolean
  onClose?: () => void
  children: ReactNode | ReactNode[]
  modalSx?: { [key: string]: any } | ((theme: Theme) => { [key: string]: any })
  cancelIcon?: boolean
}

const ModalTwo = ({ open, onClose, children, modalSx = {}, cancelIcon = true }: IProps) => {
  const theme = useTheme()

  const smallMatch = useMediaQuery(theme.breakpoints.down('sm'))

  const modalStyles = (theme: Theme) => (typeof modalSx === 'function' ? modalSx(theme) : modalSx)

  return (
    <Modal open={open} onClose={onClose} sx={{ backdropFilter: `blur(${theme.spacing(1)})` }}>
      <Box
        sx={theme => ({
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
          maxWidth: 768,
          height: '100vh',
          m: '0 auto',
          outline: 'none',
          ...modalStyles(theme)
        })}
      >
        <Box
          sx={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 1,
            height: '100%',
            maxHeight: smallMatch ? 'auto' : 562,
            bgcolor: 'background.paper',
            borderRadius: 0.5
          }}
        >
          {cancelIcon && onClose && (
            <IconButton
              color='secondary'
              size='small'
              sx={{
                position: 'absolute',
                top: theme.spacing(6),
                right: theme.spacing(6),
                p: 1
              }}
              onClick={onClose}
            >
              <Icon icon='mdi:close' fontSize={32} />
            </IconButton>
          )}
          <Box
            sx={{
              height: '100%',
              overflow: 'auto',
              p: theme.spacing(4, 6)
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalTwo
