// ** Reactt Imports
import { useEffect, useMemo, useState } from 'react'

// ** MUI Imports
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

// ** Modules Imports
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

// import { Swiper, SwiperSlide } from 'swiper/react'

// ** Source code Imorts
import { AttachType } from 'src/types/apps/chatTypes'
import NawBlock from './NawBlock'

interface IProps {
  open: boolean
  onViewerItemOpen: (state: string | null) => void
  openedItemId: string | null
  mediaAttachesList: AttachType[]
}

const Viewer = ({ open, onViewerItemOpen, openedItemId, mediaAttachesList }: IProps) => {
  const [isActiveMenu, setActiveMenu] = useState(true)

  useEffect(() => {
    let timeout: any

    const onMouseMove = () => {
      clearTimeout(timeout)

      setActiveMenu(true)

      timeout = setTimeout(() => setActiveMenu(false), 2500)
    }

    document.addEventListener('mousemove', onMouseMove)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      clearTimeout(timeout)
    }
  }, [])

  const media = useMemo(() => {
    return mediaAttachesList.find(({ _id }) => _id === openedItemId)
  }, [openedItemId, mediaAttachesList])

  return (
    <Modal
      open={open}
      onClose={() => onViewerItemOpen(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Box sx={{ position: 'absolute', top: '50%' ,left: '50%', transform: 'translate(-50%, -50%)' }}>
          <TransformWrapper>
            {({ resetTransform }) => {
              return (
                <>
                  <TransformComponent>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100vw',
                      height: '100vh'
                    }}>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: 1,
                          height: 1
                        }}
                        onClick={() => {
                          resetTransform()
                          onViewerItemOpen(null)
                        }}
                      />
                      <img
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                          position: 'relative',
                          pointerEvents: 'all'
                        }}
                        src={media?.attach}
                        alt={media?.name}
                      />
                    </Box>
                  </TransformComponent>
                  <NawBlock
                    isActiveMenu={isActiveMenu}
                    resetTransform={resetTransform}
                    onViewerItemOpen={onViewerItemOpen}
                    openedItemId={openedItemId}
                    mediaAttachesList={mediaAttachesList}
                  />
                </>
              )
            }}
          </TransformWrapper>
        </Box>
      </Box>
    </Modal>
  )
}

export default Viewer
