import { toFixed } from './number'

export const formatTime = (seconds: number) => {
  let time = Math.round(seconds)

  return (time - (time %= 60)) / 60 + (9 < time ? ':' : ':0') + time
}

export const formatTimeToSeconds = (time: string | number, rounding?: number) => {
  let data: any = String(time)

  if (data === '' || data === ':undefined' || data === 'null') return null

  data = data
    .replace(/_/g, '')
    .split(':')
    .map((item: string, index: number) => {
      if (index === 0) return Number(item) * 60

      return Number(item)
    })

  data = data[0] + data[1]

  if (typeof rounding === 'number') return toFixed(data, rounding)

  return data
}

export const formatSecondsToTime = (time: number) => {
  if (time !== 0 && !time) return ''

  let minutes: number | string = Math.floor(time / 60)
  minutes = minutes < 10 ? `0${minutes}` : minutes

  let seconds: number | string = toFixed(time % 60, 3)
  seconds = Number(seconds) < 10 ? `0${seconds}` : seconds

  const data = `${minutes}:${seconds}`

  return data
}

export const isCurrentBreakDates = (date: Date | string | number, blockedDaysCount: number) => {
  const nowDate = new Date()
  nowDate.setHours(0, 0, 0, 0)

  const nextWeekDate = new Date()
  nextWeekDate.setDate(nextWeekDate.getDate() + (blockedDaysCount - 1))

  const currentDate = new Date(date)

  const nextWeekTime = nextWeekDate.getTime()
  const currentTime = currentDate.getTime()

  return nextWeekTime > currentTime
}

export const isNewYearsEve = (date: Date | string | number) => {
  const currentDate = new Date(date)
  const currentMonth = currentDate.getMonth()

  return (currentMonth == 11 && currentDate.getDate() >= 23) || (currentMonth == 0 && currentDate.getDate() <= 16)
}

export const isValidDateString = (dateString: string | null) => {
  if (!dateString) return false

  const timestamp = Date.parse(dateString)

  return isNaN(timestamp) === false
}

export const daysToMilliseconds = (days: number) => days * 24 * 60 * 60 * 1000

export const isClosedUploadingDocumentsOnNewYear = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  return (currentMonth == 11 && currentDate.getDate() >= 13) || (currentMonth == 0 && currentDate.getDate() < 9)
}