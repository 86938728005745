import axios from 'axios'
import { createStore, createEffect } from 'effector'
import globalEndpointsConfig from 'src/configs/global-endpoints'

const getTechBreakNotificationData = createEffect(async () => {
  const { data } = await axios.get(globalEndpointsConfig.getTechBreackNotificationData())

  return data
})

const techBreakNotificationData = createStore<{
  startAt: string
  endAt: string
  loaded: boolean
}>({
  startAt: '',
  endAt: '',
  loaded: false
})
  .on(getTechBreakNotificationData.doneData, (state, newState: any) => {
    return {
      ...state,
      ...newState,
      loaded: true
    }
  })

const getGlobalNoticeData = createEffect(async () => {
  const { data } = await axios.get(globalEndpointsConfig.getGlobalNoticeData())
  const dateLastVisibleNotice: string | null = localStorage.getItem('dateLastVisibleNotice')

  if (data.displayIntervalInHours !== 0 && dateLastVisibleNotice && data.active) {
    const pastIntervalInHours = (Date.now() - Number(dateLastVisibleNotice)) / (1000 * 60 * 60)
    const timeForDisplayHasCome = data.displayIntervalInHours < pastIntervalInHours

    return { ...data, active: timeForDisplayHasCome ? data.active : false }
  }

  return data
})

const switchActivityStatusGlobalNotice = createEffect(async (payload: boolean) => {
  localStorage.setItem('dateLastVisibleNotice', Date.now().toString())

  return payload
})

const globalNotificeData = createStore<{
  data: { image: string | null, text: string }
  active: boolean
}>({
  data: { image: null, text: '' },
  active: false
})
  .on(getGlobalNoticeData.doneData, (state, newState: any) => {
    return {
      ...state,
      ...newState,
      loaded: true
    }
  })
  .on(switchActivityStatusGlobalNotice, (state, payload) => {
    return {
      ...state,
      active: payload
    }
  })

const getSharedDetail = createEffect(async () => {
  const res = await axios.post(globalEndpointsConfig.getKeyValueTable(), {
    query: {
      $and: [{ 'data.groupName': 'shared' }]
    }
  })

  const data: Record<string, any> = {}

  res.data?.data.forEach((item: {
    data: {
      groupName: string
      key: string
      value?: string
    }
  }) => {
    const { key, value } = item.data
    data[key] = value
  })

  return data
})

const shared = createStore<{
  data: Record<string, any>,
  loading: boolean
}>({
  loading: true,
  data: {}
})
  .on(getSharedDetail, (state: any) => {
    return {
      data: state.data,
      loading: true
    }
  })
  .on(getSharedDetail.doneData, (state: any, payload) => {
    return {
      loading: false,
      data: payload
    }
  })

export {
  getTechBreakNotificationData,
  getGlobalNoticeData,
  switchActivityStatusGlobalNotice,
  getSharedDetail,
}

export default {
  techBreakNotificationData: techBreakNotificationData,
  globalNotificeData,
  shared
}
