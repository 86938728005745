import { Theme } from '@mui/material/styles'
import { UserDataType } from 'src/context/types'

export function getFullUserName(user: UserDataType | null) {
  let data = ''

  if (user?.firstName) data += user?.firstName
  if (user?.lastName) data += (data ? ' ' : '') + user?.lastName

  return data
}

export function getUserEmail(user: UserDataType | null) {
  return user?.login || ''
}

export function getUserAvatar(user: UserDataType | null, { theme }: { theme: Theme }) {
  if (user?.avatarUrl) {
    return user.avatarUrl
  }

  return `/images/avatars/default-${theme.palette.mode}.jpg`
}
