// ** React Imports
import { useEffect, ReactNode, ReactElement } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'

interface GuestGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  const isNotAuthorized = !auth.loading && auth.user !== null

  useEffect(() => {
    if (isNotAuthorized) router.replace('/home')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotAuthorized])

  if (auth.loading || isNotAuthorized) return fallback

  return <>{children}</>
}

export default GuestGuard
