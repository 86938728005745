// ** React Imports
import { useState, SyntheticEvent } from 'react'

// ** MUI Imports
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Box, { BoxProps } from '@mui/material/Box'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Hooks Imports
import { useIntl } from 'react-intl'

// ** Types
import { SendMsgComponentType } from 'src/types/apps/chatTypes'
import { upsertMessage } from 'src/state-manager/chat'

// ** Source code Imports
import AttachFile from './AttachFile'

// ** Styled Components
const ChatFormWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  boxShadow: theme.shadows[1],
  padding: theme.spacing(4, 4),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper
}))

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(0, 5, 5)
}))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SendMsgForm = ({ channel }: SendMsgComponentType) => {
  // ** Hooks
  const { formatMessage } = useIntl()

  // ** State
  const [msg, setMsg] = useState<string>('')
  const [attaches, setAttaches] = useState<any[]>([])

  const isEmpty = !msg.trim() && !attaches.length

  const handleSendMsg = (e: SyntheticEvent) => {
    e.preventDefault()

    if (isEmpty || !channel) return

    upsertMessage({
      id: channel?.id,
      message: msg,
      attaches,
      channel: channel?._id
    })

    setMsg('')
    setAttaches([])
  }

  return (
    <Form onSubmit={handleSendMsg}>
      <Box
        sx={{
          position: 'relative',
          minHeight: 124,
          zIndex: 2
        }}
      >
        <ChatFormWrapper
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: 1,
            flexDirection: 'column'
          }}
        >
          <TextField
            fullWidth
            value={msg}
            size='small'
            placeholder={formatMessage({ id: 'write-message' })}
            onChange={e => {
              setMsg(e.target.value)
            }}
            onKeyPress={e => {
              if (!e.shiftKey && e.charCode === 13) {
                handleSendMsg(e)
              }
            }}
            sx={{
              mb: 2,
              '& .MuiInputBase-root': {
                pl: 0,
                pr: 0
              },
              '& fieldset': { border: '0 !important' }
            }}
            multiline
            maxRows={4}
          />
          <ButtonGroup sx={{ width: 1 }}>
            <AttachFile
              onUpload={(file: any) => {
                setAttaches(state => [
                  ...state,
                  {
                    type: file.fileType,
                    name: file.fileName,
                    attach: file.original
                  }
                ])
              }}
              onRemove={index => {
                setAttaches(state => {
                  const data = state.filter((item, indexItem) => indexItem !== index)

                  return data
                })
              }}
              files={attaches}
            />
            <Button
              sx={{
                width: '50%',
                borderRadius: 0
              }}
              type='submit'
              variant='contained'
              disabled={isEmpty}
            >
              <Icon icon='mdi:send' />
            </Button>
          </ButtonGroup>
        </ChatFormWrapper>
      </Box>
    </Form>
  )
}

export default SendMsgForm
