// ** React Imports
import { createContext, useEffect, useState, ReactNode, useRef } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Axios
import axios, { AxiosResponse } from 'axios'

// ** Config
import authConfig from 'src/configs/auth'

// ** Modules Imports
import Cookies from 'js-cookie'

// ** Types
import {
  AuthValuesType,
  RegisterParams,
  LoginParams,
  ErrCallbackType,
  SuccessCallbackType,
  UserDataType,
  ForgotParams,
  ForgotCheckCodeParams,
  ForgotChangePasswordParams,
  UpdateParams,
  UpdatePasswordParams,
  ImpersonationParams
} from './types'
import { ping } from 'src/services/emd-core/ping'
import { getNotificationsUnreadCount } from 'src/state-manager/notifications'

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  token: null,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  impersonation: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkRegister: () => Promise.resolve(),
  register: () => Promise.resolve(),
  forgot: () => Promise.resolve(),
  forgotCheckCode: () => Promise.resolve(),
  forgotChangePassword: () => Promise.resolve(),
  update: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const storedToken = Cookies.get(authConfig.storageTokenKeyName)!
      if (storedToken) {
        setLoading(true)

        axios.defaults.headers.common.Authorization = `${authConfig.headerTokenKeyName} ${storedToken}`

        await axios
          .post(authConfig.meEndpoint, {}, {})
          .then(async response => {
            const { data } = response.data as any
            setLoading(false)
            setUser({ ...data })
            setToken(storedToken)
          })
          .catch(() => {
            setUser(null)
            setToken(null)
            setLoading(false)

            if (authConfig.onTokenExpiration === 'logout' || !router.pathname.includes('login')) {
              router.replace('/login')
            }
          })
      } else {
        setUser(null)
        setToken(null)
        setLoading(false)
      }
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const interval = useRef<any>()
  useEffect(() => {
    if (user) {
      clearInterval(interval.current)

      ping()

      getNotificationsUnreadCount()

      interval.current = setInterval(() => {
        ping()
        getNotificationsUnreadCount()
      }, 60 * 1000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [user])

  const handleImpersonation = (params: ImpersonationParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(authConfig.impersonationEndpoint, { token: params.token })
      .then(async (response: any) => {
        const { data } = response.data as any

        Cookies.set(authConfig.storageTokenKeyName, data.token, { expires: params.rememberMe ? 90 : undefined })

        axios.defaults.headers.common.Authorization = `${authConfig.headerTokenKeyName} ${data.token}`

        const returnUrl = router.query.returnUrl

        setUser({ ...data })
        setToken(data.token)

        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

        router.push(redirectURL as string)
      })
      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(authConfig.loginEndpoint, { login: params.email.trim(), password: params.password })
      .then(async (response: any) => {
        const { data } = response.data as any

        Cookies.set(authConfig.storageTokenKeyName, data.token, { expires: params.rememberMe ? 90 : undefined })

        axios.defaults.headers.common.Authorization = `${authConfig.headerTokenKeyName} ${data.token}`

        const returnUrl = router.query.returnUrl

        setUser({ ...data })
        setToken(data.token)

        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

        router.push(redirectURL as string)
      })
      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleUpdate = (params: UpdateParams | UpdatePasswordParams) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.put(authConfig.updateEndpoint, params)

        const { data } = response.data

        setUser({ ...data })

        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  const handleLogout = () => {
    setUser(null)
    setToken(null)
    Cookies.remove(authConfig.storageTokenKeyName)
    router.push('/login')
  }

  const handleRegister = (params: RegisterParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(authConfig.registerEndpoint, {
        firstName: params.firstName,
        lastName: params.lastName,
        login: params.email.trim(),
        password: params.password,
        customFields: params.customFields,
        captchaToken: params.captchaToken
      })
      .then(() => {
        handleLogin({ email: params.email, password: params.password, rememberMe: true })
      })
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const handleCheckRegister = (
    { login, captchaToken }: { login: string; captchaToken: string },
    resolveCallback?: () => void,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(authConfig.checkRegisterEndpoint, {
        login: login.trim(),
        captchaToken
      })
      .then(() => {
        if (resolveCallback) resolveCallback()
      })
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const handleForgot = (
    params: ForgotParams,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(authConfig.forgotRequestEndpoint, { email: params.email })
      .then((resp: AxiosResponse) => (successCallback ? successCallback(resp) : null))
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const handleForgotCheckCode = (
    params: ForgotCheckCodeParams,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(authConfig.forgotCheckCodeEndpoint(params.requestId), { code: params.code })
      .then((resp: AxiosResponse) => (successCallback ? successCallback(resp) : null))
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const handleForgotChangePassword = (
    params: ForgotChangePasswordParams,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .put(authConfig.forgotCheckCodeEndpoint(params.requestId), {
        newPassword1: params.newPassword1,
        newPassword2: params.newPassword2
      })
      .then((resp: AxiosResponse) => (successCallback ? successCallback(resp) : null))
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const values = {
    user,
    loading,
    token,
    setUser,
    setLoading,
    impersonation: handleImpersonation,
    login: handleLogin,
    logout: handleLogout,
    checkRegister: handleCheckRegister,
    register: handleRegister,
    forgot: handleForgot,
    forgotCheckCode: handleForgotCheckCode,
    forgotChangePassword: handleForgotChangePassword,
    update: handleUpdate
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
