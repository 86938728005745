// ** MUI Imports
import Box from '@mui/material/Box'

// ** Custom Components Import
import ChatLog from './ChatLog'
import SendMsgForm from 'src/@core/components/chat/SendMsgForm'

// ** Types
import { ChatContentType } from 'src/types/apps/chatTypes'

const ChatContent = (props: ChatContentType) => {
  // ** Props
  const { channel, page, messages, isDisabledPagination, onNextPage } = props

  const renderContent = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          backgroundColor: 'action.hover'
        }}
      >
        {messages ? (
          <ChatLog
            hidden={false}
            messages={messages}
            page={page}
            isDisabledPagination={isDisabledPagination}
            onNextPage={onNextPage}
          />
        ) : null}

        <SendMsgForm channel={channel} />
      </Box>
    )
  }

  return renderContent()
}

export default ChatContent
