import axios from 'axios'
import uploaderConfig from 'src/configs/uploader'

export async function uploadFile(upload: any, storageId: string | null = null, path: string | null = null) {
  const formData = new FormData()

  formData.append('upload', upload)

  if (storageId) formData.append('storageId', storageId)
  if (path) formData.append('path', path)

  return await axios.post(uploaderConfig.uploadEndpoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
