// ** React Imports
import { useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// ** Modules Imports
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'

// ** Source code Imports
import globalStore, {
  getGlobalNoticeData,
  switchActivityStatusGlobalNotice
} from 'src/state-manager/global'
import { ModalTwo } from 'src/@core/components/modal'

const GlobalNotice = () => {
  const { formatMessage } = useIntl()
  const { data: { image, text }, active } = useStore(globalStore.globalNotificeData)

  useEffect(() => {
    getGlobalNoticeData()
  }, [])

  const onClose = () => switchActivityStatusGlobalNotice(false)

  return (
    <ModalTwo
      modalSx={theme => ({
        [theme.breakpoints.down('sm')]: {
          maxWidth: 343,
          margin: '0 auto',
          maxHeight: 593
        }
      })}
      open={active}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100%'
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              width: 240,
              height: 'auto'
            }}
            component='img'
            alt={text}
            src={image || '/icons/notification-bell.svg'}
          />
          {text && (
            <Typography sx={theme => ({ m: theme.spacing(4, 0), maxWidth: 456 })} variant='subtitle1'>
              {text}
            </Typography>
          )}
          <Button
            sx={theme => ({ m: theme.spacing(4, 0) })}
            variant='contained'
            onClick={onClose}
          >
            {formatMessage({ id: 'close' })}
          </Button>
        </Box>
      </Box>
    </ModalTwo>
  )
}

export default GlobalNotice
