// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'

// ** Icon Imports
import { Icon, IconProps } from '@iconify/react'

const IconifyIcon = ({ icon, sx, ...rest }: IconProps & BoxProps) => {
  return <Box sx={sx} component={Icon} icon={icon} fontSize='1.5rem' {...rest} />
}

export default IconifyIcon
