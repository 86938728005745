import axios from 'axios'
import { createStore, createEffect } from 'effector'
import notificationsConfig from 'src/configs/notifications'

const getNotificationsList = createEffect(async (payload: Record<string, any> = {}) => {
  const { data } = await axios.post(notificationsConfig.getNotificationsList(), payload)

  return data
})

const getNotificationsUnreadCount = createEffect(async () => {
  const { data } = await axios.post(notificationsConfig.getNotificationsUnreadCount(), {
    query: {
      ['data.readed']: false
    }
  })

  return data
})

const setUnreadedCount = createEffect((payload: any) => ({ unreaded: payload }))

const readAllNotice = createEffect(async () => {
  const { data } = await axios.post(notificationsConfig.readAllNotice(), {
    notice: '',
    data: {
      readed: true
    },
    query: {
      ['data.readed']: false
    }
  })

  return data
})

const readNotice = createEffect(async (payload: any) => {
  const { data } = await axios.put(notificationsConfig.getNotificationsList(), {
    _id: payload._id,
    data: {
      ...payload.data,
      readed: true
    }
  })

  return data
})

const notifications = createStore<any>({
  data: [],
  count: 0,
  pages: 0,
  unreaded: 0,
  loading: true
})
  .on(getNotificationsList, state => {
    return {
      ...state,
      loading: true
    }
  })
  .on(getNotificationsList.doneData, (state, payload: any) => {
    return {
      ...state,
      data: payload.data,
      count: payload.count,
      pages: payload.pages,
      loading: false
    }
  })
  .on(getNotificationsUnreadCount.doneData, (state, payload: any) => {
    return {
      ...state,
      unreaded: payload.count
    }
  })
  .on(readAllNotice.doneData, state => {
    return {
      ...state,
      unreaded: 0
    }
  })
  .on(setUnreadedCount.doneData, (state, payload) => {
    return {
      ...state,
      unreaded: payload.unreaded
    }
  })
  .on(readNotice.doneData, (state, payload) => {
    const data = state.data.filter((item: any) => {
      return payload.data._id === item._id
    })

    return {
      ...state,
      data,
      unreaded: state.unreaded - 1
    }
  })

const getModalNotificationsList = createEffect(async () => {
  const { data } = await axios.post(notificationsConfig.getNotificationsList(), {
    limit: 10,
    page: 0,
    query: {
      ['data.readed']: false,
      ['data.variation']: 'modal'
    }
  })

  return data
})

export type NotificationItem = {
  _id: string
  data: {
    title: string
    text: string
    icon: string
    image?: string | null
    link: string
    typeLink: string
  }
}

const modalNotifications = createStore<{
  data: NotificationItem[]
  loading: boolean
}>({
  data: [],
  loading: true
})
  .on(getModalNotificationsList, state => {
    return {
      ...state,
      loading: true
    }
  })
  .on(getModalNotificationsList.doneData, (state, payload: any) => {
    return {
      ...state,
      data: payload.data,
      loading: false
    }
  })
  .on(readNotice.doneData, (state, payload) => {
    const data = state.data.filter((item: any) => {
      return payload.data._id !== item._id
    })

    return { ...state, data }
  })

export {
  getModalNotificationsList,
  getNotificationsList,
  getNotificationsUnreadCount,
  readAllNotice,
  readNotice,
  setUnreadedCount
}
export default { notifications, modalNotifications }
