// ** MUI Imports
import { Theme } from '@mui/material/styles'

// ** Theme Config Imports
import themeConfig from 'src/configs/themeConfig'

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 500,
          borderRadius: 2,
          lineHeight: 1.7,
          letterSpacing: '0.46px',
          padding: `${theme.spacing(2, 3)}`,
          textTransform: 'none'
        },
        specified: {
          backgroundColor: theme.palette.specified.main,
          color: theme.palette.specified.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.specified.hover.main,
            color: theme.palette.specified.hover.contrastText
          }
        },
        contained: {
          boxShadow: theme.shadows[3],
          padding: `${theme.spacing(2.25, 6.5)}`
        },
        outlined: {
          padding: `${theme.spacing(2, 6.5)}`
        },
        sizeSmall: {
          padding: `${theme.spacing(1, 2.25)}`,
          '&.MuiButton-contained': {
            padding: `${theme.spacing(1, 3.5)}`
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(0.75, 3.25)}`
          }
        },
        sizeLarge: {
          padding: `${theme.spacing(2.125, 5.5)}`,
          '&.MuiButton-contained': {
            padding: `${theme.spacing(2.125, 6.5)}`
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(1.875, 6.25)}`
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: themeConfig.disableRipple
      }
    }
  }
}

export default Button
