const mediaQueries = {
  desktopLarge: '(max-width:1920px)',
  desktopMedium: '(max-width:1440px)',
  desktopSmall: '(max-width:1280px)',
  tabletLarge: '(max-width:900px)',
  tabletMedium: '(max-width:768px)',
  tabletMediumSmall: '(max-width:599px)',
  tabletSmall: '(max-width:560px)',
  mobileLarge: '(max-width:425px)',
  mobileMedium: '(max-width:375px)',
  mobileSmall: '(max-width:320px)'
} as const

export default mediaQueries
