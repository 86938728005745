import apiConfig from './api'

type ConfigType = {
  uploadEndpoint: string
  uppy: {
    tus: {
      enabled: boolean
      endpoint: string
      chunkSize: number
    }
    emdUploader: {
      enabled: boolean
    }
    goldenRetreaver: {
      enabled: boolean
      serviceWorker: boolean
    },
    fileUrl: string
  }
  uploaderName: 'tus' | 'emd'
}

export default {
  uploadEndpoint: `${apiConfig.apiEndpoint}/api/${apiConfig.space}/uploader`,
  uppy: {
    tus: {
      enabled: true,
      endpoint: `${apiConfig.apiEndpoint}/api/${apiConfig.space}/uploader/chunk/default/s3/`,
      chunkSize: 64 * 1024 * 1024
    },
    emdUploader: {
      enabled: false
    },
    goldenRetreaver: {
      enabled: false,
      serviceWorker: false
    },
    fileUrl: `${apiConfig.apiEndpoint}/api/${apiConfig.space}/uploader/chunk/default/file`
  },
  uploaderName: 'tus'
} as ConfigType
