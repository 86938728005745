// ** React Imports
import { useState, useEffect, Fragment } from 'react'

// ** MUI Imports
import { Box, Button, Container, IconButton, Typography } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Modules Imports
import { useIntl } from 'react-intl'
import * as Bowser from 'bowser'
import Cookies from 'js-cookie'

// ** Source code Imports
import BrowserItem from './BrowserItem'
import { ModalTwo } from 'src/@core/components/modal'
import supportedBrowsers from 'src/configs/supported-browsers'

const browsersList: {
  key: 'chrome' | 'mozilla' | 'opera' | 'edge' | 'yandex'
  name: string
  link: string
}[] = [
  {
    key: 'yandex',
    name: 'Яндекс.Браузер',
    link: 'https://browser.yandex.ru/'
  },
  {
    key: 'mozilla',
    name: 'Mozilla Firefox',
    link: 'https://www.mozilla.org/ru/firefox/new/'
  },
  {
    key: 'chrome',
    name: 'Google Chrome',
    link: 'https://www.google.ru/chrome/'
  },
  {
    key: 'opera',
    name: 'Opera',
    link: 'https://www.opera.com/ru'
  },
  {
    key: 'edge',
    name: 'Microsoft Edge',
    link: 'https://www.microsoft.com/ru-ru/edge'
  }
]

const UpdateBrowser = () => {
  const { formatMessage } = useIntl()

  const [open, setOpen] = useState<boolean>(false)
  const [browserInfo, setBrowserInfo] = useState<{
    name?: string | undefined
    version?: string | undefined
    isInvalidBrowser: boolean | undefined
  }>({
    name: '',
    version: '',
    isInvalidBrowser: false
  })
  const [isUpdateMessageShowed, setUpdateMessageShowed] = useState<boolean>(true)

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isInvalidBrowser = browser.satisfies(supportedBrowsers)

    setBrowserInfo({
      isInvalidBrowser,
      ...browser.getBrowser()
    })

    const updateMessageShowed = Cookies.get('updateMessageShowed')

    setUpdateMessageShowed(!!updateMessageShowed)
  }, [])

  const setMessageShowed = () => {
    Cookies.set('updateMessageShowed', 'true', { expires: 1 })
    setUpdateMessageShowed(true)
  }

  const onCloseModal = () => {
    setMessageShowed()
    setOpen(false)
  }

  if (browserInfo.isInvalidBrowser) return null

  return (
    <Fragment>
      {!isUpdateMessageShowed && !open && (
        <Box
          sx={{
            background: '#3A3E5B',
            width: '100%',
            padding: '15px 0',
            position: 'fixed',
            bottom: 0,
            zIndex: 9999
          }}
        >
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '&.MuiContainer-root': { maxWidth: '1200px', padding: '0 0.75rem' },
              '@media (max-width:768px)': {
                flexDirection: 'column'
              }
            }}
          >
            <Box
              sx={{
                maxWidth: '850px',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#fff',
                '@media (max-width:1024px)': {
                  maxWidth: '720px'
                },
                '@media (max-width:768px)': {
                  fontSize: '12px',
                  mb: '16px'
                }
              }}
            >
              {formatMessage(
                { id: 'your-browser-needs-update' },
                {
                  name: browserInfo?.name,
                  version: browserInfo?.version
                }
              )}
            </Box>
            <Box>
              <Button
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                  background: '#9000FF',
                  padding: '8px 26px',
                  fontSize: '15px',
                  fontWeight: '500',
                  color: '#fff',
                  textTransform: 'capitalize',
                  mr: 4,
                  '@media (max-width:768px)': {
                    margin: 'auto'
                  }
                }}
                onClick={() => setOpen(true)}
              >
                Обновить
              </Button>
              <IconButton onClick={setMessageShowed}>
                <Icon fontSize={28} icon='mdi:close' />
              </IconButton>
            </Box>
          </Container>
        </Box>
      )}
      <ModalTwo open={open} onClose={onCloseModal}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant='h5' textAlign='center' mb={6}>
            Установите актуальную версию вашего браузера или одну из современных альтернатив
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {browsersList.map(({ key, name, link }) => (
              <BrowserItem sx={{ m: 3 }} key={key} browser={key} browserName={name} link={link} />
            ))}
          </Box>
        </Box>
      </ModalTwo>
    </Fragment>
  )
}

export default UpdateBrowser
