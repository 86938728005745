// ** MUI Imports
import { Theme } from '@mui/material/styles'

const form = (theme: Theme) => {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary
        }
      }
    }
  }
}

export default form
