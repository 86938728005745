// ** MUI Imports
import Button from '@mui/material/Button'

// ** Modules Imports
import { useIntl } from 'react-intl'

// ** Context
import { useAuth } from 'src/hooks/useAuth'

// ** Source code Imports
import ErrorPage from 'src/views/pages/error-page'

const AccountRegectedPage = () => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const { logout } = useAuth()

  return (
    <ErrorPage
      title={formatMessage({ id: 'account-regected-title' })}
      description={formatMessage({ id: 'account-regected-description' })}
      renderButton={(
        <Button variant='contained' onClick={logout}>
          {formatMessage({ id: 'logout' })}
        </Button>
      )}
    />
  )
}

export default AccountRegectedPage
