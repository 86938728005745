// ** Module Imports
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

const useTimeFormat = () => {
  const { locale } = useIntl()

  const formatDate = (value: Date, formule: string) => dayjs(value).locale(locale).format(formule)

  const formatPlaceholder = (data: string) => {
    let placeholder: any = data

    if (locale === 'ru') {
      placeholder = data
        .split('')
        .map(item => {
          if (item === 'm') return 'м'
          if (item === 'h') return 'ч'
          if (item === 'd') return 'д'
          if (item === 'y') return 'г'

          return item
        })
        .join('')
    }

    return placeholder
  }

  return { formatDate, formatPlaceholder }
}

export default useTimeFormat
