// ** React Imports
import { useMemo } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Modules Imports
import { useIntl } from 'react-intl'

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const currentYear = useMemo(() => new Date().getFullYear(), [])

  const { formatMessage } = useIntl()

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography sx={{ mr: 2, color: 'text.secondary' }}>
        {formatMessage({ id: 'copyright' }, { year: `2023-${currentYear}` })}
        {'. '}
        {formatMessage({ id: 'all-right-reserved' })}
      </Typography>
      {hidden ? null : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
          <Box component={Link} href='/cookies-policy' sx={{ color: 'text.secondary' }}>
            {formatMessage({ id: 'cookies-policy' })}
          </Box>
          <Box component={Link} href='/privacy-policy' sx={{ color: 'text.secondary' }}>
            {formatMessage({ id: 'privacy-policy' })}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default FooterContent
