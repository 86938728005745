// ** MUI Imports
import { Box, Button, Typography } from '@mui/material'

// ** Modules Imports
import { useIntl } from 'react-intl'

interface IProps {
  sx?: { [key: string]: any }
  link: string
  browser?: 'chrome' | 'mozilla' | 'opera' | 'edge' | 'yandex'
  browserName?: string
}

const BrowserItem = ({ sx = {}, browser = 'chrome', browserName = 'Google Chrome', link }: IProps) => {
  const { formatMessage } = useIntl()

  const backgroundPosition = {
    chrome: '0 0',
    mozilla: '-76px 0',
    opera: '-306px 0',
    edge: '-152px 0',
    yandex: '-230px 0'
  }[browser]

  return (
    <Box
      sx={{
        maxWidth: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}
    >
      <Box
        component='a'
        href={link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textDecoration: 'none',
          '&:hover div': {
            transform: 'scale(1.15)'
          }
        }}
      >
        <Box
          sx={{
            backgroundImage: 'url(/icons/browsers-icons.png)',
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            width: 75,
            height: 75,
            backgroundPosition,
            mb: 1
          }}
        />
        <Typography sx={{ mb: 2 }} variant='subtitle1'>
          {browserName}
        </Typography>
      </Box>
      <Button component='a' href={link} target='_blank' sx={{ pt: 1, pb: 1 }} variant='outlined'>
        {formatMessage({ id: 'download' })}
      </Button>
    </Box>
  )
}

export default BrowserItem
