// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

// ** Source code Imports
import { Logotype } from 'src/layouts/components/icons'

const FallbackSpinner = ({ sx, settings }: { sx?: BoxProps['sx']; settings: any }) => {
  const { mode } = settings

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx
      }}
    >
      <Logotype width={82} height={68} mode={mode} />
      <CircularProgress disableShrink sx={{ mt: 6 }} />
    </Box>
  )
}

export default FallbackSpinner
