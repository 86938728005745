// ** Rect Imports
import { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Alert, { AlertProps } from '@mui/material/Alert'

// ** Modules Imports
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'

// ** Source code Imports
import snackbarStore, { closeAlert } from 'src/state-manager/snackbar'

interface ISnackbarProps {
  children: any
  onClose: () => void
  open: boolean
  autoHideDuration: number
  servity?: AlertProps['severity']
  variant?: 'outlined' | 'standard' | 'filled'
  sx?: { [key: string]: any }
}

const Snackbar = ({ children, onClose, open, autoHideDuration, servity, variant, sx }: ISnackbarProps) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (open) setActive(open)

    const timeout = setTimeout(() => {
      setActive(false)

      setTimeout(onClose, 300)
    }, autoHideDuration)

    return () => clearTimeout(timeout)
  }, [open])

  const handleClose = () => {
    setActive(false)

    setTimeout(onClose, 300)
  }

  if (!open) return null

  return (
    <Box
      sx={{
        ...sx,
        opacity: active ? 1 : 0,
        transitionDuration: '250ms',
        transitionProperty: 'opacity'
      }}
    >
      <Alert sx={{ width: 1 }} severity={servity} variant={variant} onClose={handleClose}>
        {children}
      </Alert>
    </Box>
  )
}

const SnackbarAlerts = () => {
  // ** Hooks
  const { formatMessage } = useIntl()

  const { alerts } = useStore(snackbarStore.snackbar)

  const onClose = (id: string) => closeAlert(id)

  return (
    <Box
      sx={theme => ({
        position: 'fixed',
        top: theme.spacing(20),
        right: theme.spacing(6),
        width: 320,
        zIndex: 9999,
        [theme.breakpoints.down('sm')]: {
          top: theme.spacing(16),
          right: 'auto',
          width: 1
        }
      })}
    >
      {alerts.map(item => {
        const message = {
          formatMessage: formatMessage(
            { id: item.message.value },
            item.message.opts
          ),
          custom: item.message.value
        }[item.message.type]

        return (
          <Snackbar
            key={item.id}
            sx={{
              mt: 2,
              mb: 2,
              position: 'static'
            }}
            open={item.open}
            autoHideDuration={item.autoHideDuration}
            onClose={() => onClose(item.id)}
            servity={item.alertSeverity}
            variant={item.variant}
          >
            {message}
          </Snackbar>
        )
      })}
    </Box>
  )
}

export default SnackbarAlerts
