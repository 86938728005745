// ** React Imports
import { ReactNode } from 'react'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Modules Imports
import { useIntl } from 'react-intl'

// ** Source code Imports
import ErrorPage from 'src/views/pages/error-page'

const Error401 = () => {
  const { formatMessage } = useIntl()

  return (
    <ErrorPage
      code={401}
      title={formatMessage({ id: 'unauthorized-title' })}
      description={formatMessage({ id: 'unauthorized-description' })}
    />
  )
}

Error401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default Error401
