// ** Next Imports
import { useRouter } from 'next/router'

// ** MUI Imports
import Button from '@mui/material/Button'

// ** Modules Imports
import { useIntl } from 'react-intl'

// ** Source code Imports
import ErrorPage from 'src/views/pages/error-page'

const TechnicalBreackPage = () => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const { reload } = useRouter()

  return (
    <ErrorPage
      title={formatMessage({ id: 'technical-breack-title' })}
      description={`${formatMessage({ id: 'technical-breack-description' })}.`}
      renderButton={(
        <Button variant='contained' onClick={reload}>
          {formatMessage({ id: 'refresh-page' })}
        </Button>
      )}
    />
  )
}

export default TechnicalBreackPage
