// ** React Imports
import { useEffect, useRef, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Hooks Imports
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

// ** Source code imports
import { createAlert } from 'src/state-manager/snackbar'
import { uploadFile } from 'src/services/emd-core'

const limit = 10

const onErrorMaximumAttachFiles = () => createAlert({
  autoHideDuration: 2500,
  action: false,
  open: true,
  message: {
    type: 'formatMessage',
    value: 'error-maximum-attach-files',
    opts: { count: limit }
  },
  disableTranslation: true,
  variant: 'filled',
  alertSeverity: 'error',
  actionButton: false
})

interface IProps {
  storageId?: string | null
  path?: string | null
  onUpload: (data: any) => void
  onRemove?: (index: number) => void
  files: any[]
}

const AttachFile = ({
  storageId = null,
  path = null,
  onUpload,
  onRemove = () => {
    /* Empty */
  },
  files
}: IProps) => {
  const { formatMessage } = useIntl()

  const anchorRef = useRef<any>(null)

  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  // TODO: нужно заменить этот uploader на tus
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'text/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/json': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    },
    onDrop: async (acceptedFiles: any) => {
      if (files.length === limit) {
        onErrorMaximumAttachFiles()

        return
      }

      try {
        setLoading(true)

        const filesLeft = limit - files.length
        const countFiles = filesLeft > acceptedFiles.length ? acceptedFiles.length : filesLeft

        for (let i = 0; i < countFiles; ++i) {
          const file = acceptedFiles[i]
          const { data: resp } = await uploadFile(file, storageId, path)

          onUpload({ ...resp.data, name: file.name })
        }

        createAlert({
          autoHideDuration: 2500,
          action: false,
          open: true,
          message: { type: 'formatMessage', value: 'files-uploaded-success' },
          variant: 'filled',
          alertSeverity: 'success',
          actionButton: false
        })

        if (acceptedFiles.length > countFiles) onErrorMaximumAttachFiles()

        setLoading(false)
      } catch (e: any) {
        createAlert({
          autoHideDuration: 3000,
          action: false,
          open: true,
          message: { type: 'formatMessage', value: 'error-by-upload-files' },
          variant: 'filled',
          alertSeverity: 'error',
          actionButton: false
        })

        setLoading(false)
      }
    }
  })

  useEffect(() => {
    const clickOutside = (evt: MouseEvent) => {
      if (!anchorRef?.current?.contains(evt.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('click', clickOutside)
    }
  }, [anchorRef])

  return (
    <Box ref={anchorRef} sx={{ position: 'relative', width: '50%' }}>
      <Box
        sx={{
          width: 280,
          bgcolor: 'background.paper',
          position: 'absolute',
          bottom: '100%',
          mb: 4,
          visibility: open ? 'visible' : 'hidden',
          opacity: open ? 1 : 0,
          transform: `translate(${open ? '0,0' : '-25%,50%'}) scale(${open ? 1 : 0.5})`,
          transitionProperty: 'opacity scale visibility',
          transitionDuration: '150ms',
          zIndex: 2,
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        <List
          sx={{
            width: 1,
            p: 2,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 300
          }}
        >
          {!files.length && (
            <Typography sx={{ textAlign: 'center', mb: 2 }} color='text.disabled'>
              {formatMessage({ id: 'empty' })}
            </Typography>
          )}
          {files.map(({ name }, index: number) => (
            <ListItem key={index}>
              <Icon icon='mdi:file' />
              <Typography
                sx={{
                  width: 'calc(100% - 72px)',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'pre',
                  mx: 2
                }}
              >
                {name}
              </Typography>
              <IconButton size='small' onClick={() => setTimeout(() => onRemove(index))}>
                <Icon icon='mdi:trash' />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          sx={{ width: 1, borderRadius: 0 }}
          variant='contained'
          color='primary'
          disabled={isLoading}
          {...getRootProps()}
        >
          <Icon icon='mdi:upload' />
          <input id='upload-file' {...getInputProps()} />
        </Button>
      </Box>
      {!!files.length && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 100,
            width: 16,
            height: 16,
            backgroundColor: 'white',
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            transform: 'translate(25%, -50%)',
            zIndex: 1
          }}
        >
          <Typography color='black' variant='body3' lineHeight={0.5} fontWeight={400}>
            {files.length}
          </Typography>
        </Box>
      )}
      <Button
        variant='contained'
        color='secondary'
        sx={theme => ({
          width: 1,
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.common.black,
          color: theme.palette.text.primary,
          borderRadius: 0,
          '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.common.dark,
            color: theme.palette.text.primary
          }
        })}
        onClick={() => setOpen(true)}
      >
        <Icon icon='mdi:attachment' />
      </Button>
    </Box>
  )
}

export default AttachFile
