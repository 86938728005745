import { CSSProperties } from 'react'

interface IProps {
  id: 'appleMusic' | 'vkMusic'
  size?: number | string
  style?: CSSProperties
}

const PlatformIcon = ({ id, size = 24, style = {} }: IProps) => {
  if (id === 'appleMusic')
    return (
      <svg width={size} height={size} style={style} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_363_17431)'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M24 7.50751C24 7.22083 24 6.93416 23.9987 6.64749C23.9973 6.40615 23.9947 6.16481 23.988 5.92347C23.974 5.39746 23.9427 4.86745 23.8493 4.34743C23.7547 3.81942 23.5993 3.32808 23.3553 2.84807C23.1153 2.37672 22.802 1.94605 22.428 1.57204C22.054 1.19803 21.6227 0.884687 21.1513 0.644682C20.672 0.400676 20.1807 0.246006 19.6533 0.15067C19.1333 0.0573347 18.6027 0.0260006 18.0767 0.0120003C17.8353 0.00533346 17.594 0.00266673 17.3527 0.00133336C17.066 0 16.7793 0 16.4927 0H7.50733C7.22067 0 6.934 0 6.64733 0.00133336C6.406 0.00266673 6.16467 0.00533346 5.92333 0.0120003C5.39733 0.0266673 4.86667 0.0573347 4.34667 0.151337C3.81867 0.246006 3.328 0.401343 2.84867 0.645348C2.37733 0.885354 1.946 1.19869 1.572 1.5727C1.198 1.94671 0.884667 2.37739 0.644667 2.84873C0.400667 3.32874 0.245333 3.82009 0.150667 4.3481C0.0573333 4.86811 0.026 5.39879 0.012 5.92414C0.00533333 6.16548 0.00266667 6.40682 0.00133333 6.64815C0 6.93416 0 7.22083 0 7.50751V16.4924C0 16.7791 0 17.0657 0.00133333 17.3524C0.00266667 17.5937 0.00533333 17.8351 0.012 18.0764C0.026 18.6024 0.0573333 19.1324 0.150667 19.6525C0.245333 20.1805 0.400667 20.6718 0.644667 21.1518C0.884667 21.6232 1.198 22.0538 1.572 22.4279C1.946 22.8019 2.37733 23.1152 2.84867 23.3552C3.328 23.5992 3.81933 23.7539 4.34667 23.8492C4.86667 23.9426 5.39733 23.9739 5.92333 23.9879C6.16467 23.9946 6.406 23.9972 6.64733 23.9986C6.934 24.0006 7.22067 23.9999 7.50733 23.9999H16.492C16.7787 23.9999 17.0653 23.9999 17.352 23.9986C17.5933 23.9972 17.8347 23.9946 18.076 23.9879C18.602 23.9739 19.1327 23.9426 19.6527 23.8492C20.1807 23.7546 20.6713 23.5992 21.1507 23.3552C21.622 23.1152 22.0533 22.8019 22.4273 22.4279C22.8013 22.0538 23.1147 21.6232 23.3547 21.1518C23.5987 20.6718 23.754 20.1805 23.8487 19.6525C23.942 19.1324 23.9733 18.6018 23.9873 18.0764C23.994 17.8351 23.9967 17.5937 23.998 17.3524C24 17.0657 23.9993 16.7791 23.9993 16.4924V7.50751H24Z'
            fill='url(#paint0_linear_363_17431)'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M16.9197 3.65673C16.8619 3.66205 16.348 3.75313 16.2861 3.76576L9.17256 5.20111L9.1699 5.20177C8.98442 5.241 8.83882 5.30682 8.72647 5.40122C8.59085 5.5149 8.51572 5.67579 8.48713 5.86327C8.48115 5.90316 8.47118 5.98427 8.47118 6.10394C8.47118 6.10394 8.47118 13.3717 8.47118 15.0072C8.47118 15.2153 8.45456 15.4174 8.31362 15.5896C8.17267 15.7618 7.99849 15.8136 7.79439 15.8548C7.63949 15.8861 7.48459 15.9173 7.32968 15.9486C6.74198 16.0669 6.35971 16.1474 6.01334 16.2817C5.68226 16.41 5.43428 16.5735 5.23683 16.7809C4.84525 17.1911 4.68636 17.7476 4.74087 18.2688C4.78741 18.7136 4.98752 19.1391 5.33123 19.4535C5.56326 19.6663 5.85312 19.8278 6.19483 19.8963C6.54918 19.9674 6.9268 19.9428 7.4786 19.8311C7.77245 19.772 8.04769 19.6796 8.30963 19.5247C8.56891 19.3717 8.79096 19.1676 8.96447 18.919C9.13866 18.6697 9.25101 18.3925 9.31284 18.098C9.37666 17.7941 9.39195 17.5196 9.39195 17.2164V9.49452C9.39195 9.081 9.50896 8.97197 9.8427 8.89086C9.8427 8.89086 15.7556 7.69817 16.0315 7.64432C16.4164 7.57053 16.5979 7.68022 16.5979 8.08377V13.3551C16.5979 13.5639 16.5959 13.7753 16.4537 13.9481C16.3127 14.1203 16.1385 14.1722 15.9344 14.2134C15.7795 14.2447 15.6246 14.2759 15.4697 14.3071C14.882 14.4255 14.4998 14.5059 14.1534 14.6402C13.8223 14.7685 13.5743 14.9321 13.3769 15.1395C12.9853 15.5497 12.8125 16.1062 12.867 16.6274C12.9135 17.0721 13.1276 17.4976 13.4713 17.8121C13.7033 18.0248 13.9932 18.1817 14.3349 18.2509C14.6892 18.322 15.0669 18.2967 15.6187 18.1857C15.9125 18.1265 16.1877 18.0381 16.4497 17.8832C16.709 17.7303 16.931 17.5262 17.1045 17.2776C17.2787 17.0283 17.3911 16.751 17.4529 16.4565C17.5167 16.1527 17.5194 15.8781 17.5194 15.575V4.28565C17.5207 3.87612 17.3046 3.62349 16.9197 3.65673Z'
            fill='white'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_363_17431'
            x1='12'
            y1='23.9075'
            x2='12'
            y2='0.517251'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#FA233B' />
            <stop offset='1' stop-color='#FB5C74' />
          </linearGradient>
          <clipPath id='clip0_363_17431'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )

  if (id === 'vkMusic')
    return (
      <svg width={size} height={size} style={style} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_363_17432)'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3.69822 0H20.3018C22.3442 0 24 1.65576 24 3.69822V20.3018C24 22.3442 22.3442 24 20.3018 24H3.69822C1.65576 24 0 22.3442 0 20.3018V3.69822C0 1.65576 1.65576 0 3.69822 0Z'
            fill='#4C75A3'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M11.7989 16.8007H12.7806C12.7806 16.8007 13.0771 16.768 13.2287 16.6049C13.368 16.455 13.3635 16.1737 13.3635 16.1737C13.3635 16.1737 13.3443 14.8564 13.9556 14.6624C14.5584 14.4712 15.3324 15.9355 16.1526 16.4986C16.7729 16.9246 17.2443 16.8313 17.2443 16.8313L19.4378 16.8007C19.4378 16.8007 20.5851 16.7299 20.0411 15.8277C19.9965 15.7541 19.7241 15.1604 18.4101 13.9408C17.0346 12.6642 17.219 12.8707 18.8758 10.6626C19.8847 9.31779 20.288 8.49685 20.162 8.14525C20.0419 7.81026 19.2998 7.89875 19.2998 7.89875L16.8301 7.91402C16.8301 7.91402 16.6469 7.88909 16.5112 7.9703C16.3785 8.04971 16.2933 8.23525 16.2933 8.23525C16.2933 8.23525 15.9022 9.2758 15.3811 10.1609C14.2814 12.0282 13.8416 12.127 13.6619 12.0109C13.2436 11.7406 13.3481 10.9254 13.3481 10.346C13.3481 8.53629 13.6226 7.78175 12.8136 7.58643C12.5452 7.52166 12.3475 7.4788 11.6609 7.4718C10.7796 7.46283 10.0339 7.47452 9.61159 7.6814C9.33061 7.819 9.11384 8.12553 9.24594 8.14317C9.40921 8.16492 9.77879 8.24294 9.97474 8.50957C10.2279 8.85399 10.219 9.62715 10.219 9.62715C10.219 9.62715 10.3645 11.7575 9.87943 12.022C9.54658 12.2035 9.0899 11.833 8.10944 10.1388C7.60719 9.27105 7.22783 8.31171 7.22783 8.31171C7.22783 8.31171 7.15478 8.13247 7.0243 8.03652C6.86606 7.92027 6.64495 7.88343 6.64495 7.88343L4.29804 7.89875C4.29804 7.89875 3.94581 7.90858 3.81637 8.0618C3.70122 8.19818 3.80718 8.4799 3.80718 8.4799C3.80718 8.4799 5.64443 12.7785 7.72494 14.9447C9.63281 16.931 11.7989 16.8007 11.7989 16.8007Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_363_17432'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )

  return null
}

export default PlatformIcon
