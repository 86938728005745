const supportedBrowsers = {
  windows: {
    'internet explorer': '>11'
  },
  mobile: {
    'android browser': '>3.10'
  },
  edge: '>=79',
  firefox: '>=67',
  opera: '>=51',
  chrome: '>=64',
  safari: '>=13',
  yandex: '>=19'
}

export default supportedBrowsers
