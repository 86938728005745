import axios from 'axios'
import { createStore, createEffect, createEvent } from 'effector'
import chatConfig from 'src/configs/chat'
import { unionBy } from 'lodash'

const upsertMessage = createEffect(
  async ({ id, message, attaches, channel }: { id: string; message: string; attaches: any; channel: string }) => {
    const { data } = await axios.put(chatConfig.getChatMessageEndpoint(id), {
      message,
      attaches,
      channel
    })

    return data
  }
)

const getChatChannel = createEffect(async ({ id, query }: { id: string; query?: Record<string, any> }) => {
  const { data } = await axios.get(chatConfig.getChatChannelEndpoint(id), {
    params: query
  })

  return data
})

const upsertUserChannel = createEffect(async ({ type }: { type: string }) => {
  const { data } = await axios.post(chatConfig.upsertChatChannelEndpoint(type), {})

  return data
})

const getMessages = createEffect(
  async ({
    id,
    limit,
    page = 0,
    merge = 'before'
  }: {
    id: string
    limit: number
    page?: number
    merge?: 'before' | 'after'
  }) => {
    const { data } = await axios.get(chatConfig.getChatMessageEndpoint(id), {
      params: {
        limit,
        page
      }
    })

    return { ...data, merge }
  }
)

const clearMessages = createEvent()
const createMessage = createEvent()
const removeMessage = createEvent()
const updateChannel = createEvent()

const chat = createStore<any>({
  channel: null,
  messages: [],
  count: 0,
  pages: 0
})
  .on([upsertUserChannel.doneData, getChatChannel.doneData], (state, payload: any) => {
    return {
      ...state,
      channel: payload.data
    }
  })
  .on(getMessages.doneData, (state, payload: any) => {
    let messages = []

    if (payload.merge === 'before') messages = unionBy([...payload.data, ...state.messages], '_id') as any
    else if (payload.merge === 'after') messages = unionBy([...state.messages, ...payload.data], '_id') as any

    return {
      ...state,
      messages,
      count: payload.count,
      pages: payload.pages
    }
  })
  .on(clearMessages, state => ({
    ...state,
    messages: []
  }))
  .on([createMessage, upsertMessage.doneData], (state, payload: any) => {
    const messages = unionBy([...state.messages, payload.data], '_id') as any

    const limit = chatConfig.limitMessages

    if (messages.length > limit) {
      messages.shift()
    }

    return {
      ...state,
      messages
    }
  })
  .on(removeMessage, (state: any, payload: any) => {
    const messages = state.messages.filter((item: any) => item._id !== payload.data._id)

    return {
      ...state,
      messages,
      count: state.count - 1
    }
  })
  .on(updateChannel, (state: any, payload: any) => {
    return {
      ...state,
      channel: { ...state.chanel, ...payload }
    }
  })

export {
  upsertUserChannel,
  clearMessages,
  getMessages,
  createMessage,
  removeMessage,
  upsertMessage,
  updateChannel,
  getChatChannel
}
export default { chat }
