// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect, useRef } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext'
import useChat from 'src/hooks/useChat'
import { useAuth } from 'src/hooks/useAuth'

// ** Effector
import { useStore } from 'effector-react'

// ** Stores
import chatStore, { getChatChannel, updateChannel } from 'src/state-manager/chat'

// ** Hooks Imports
import { useIntl } from 'react-intl'

// ** Chat app
import ChatContent from 'src/@core/components/chat/ChatContent'

// ** Chat config
import chatConfig from 'src/configs/chat'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

interface Props {
  settings: Settings
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const ChatDropdown = (props: Props) => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const router = useRouter()
  const { user } = useAuth()
  const { channel, messages } = useStore(chatStore.chat)
  const { formatMessage } = useIntl()

  const limit = chatConfig.limitMessages

  const { isDisabledPagination, onNextPage, page } = useChat({
    id: user?._id,
    typeChannel: 'staff-to-user',
    limit
  })

  const interval = useRef<any>()
  useEffect(() => {
    if (!user) return

    clearInterval(interval.current)

    if (Boolean(anchorEl)) {
      updateChannel({
        unreadCountRecipient: 0
      } as any)

      getChatChannel({ id: `staff-to-user-${user?._id}` })
    }

    interval.current = setInterval(() => {
      getChatChannel({ id: `staff-to-user-${user?._id}`, query: { cleanupUnreaded: Boolean(anchorEl) } })
    }, 5000)

    return () => {
      clearTimeout(interval.current)
    }
  }, [anchorEl, user])

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
        <Badge
          invisible={!channel || channel?.unreadCountRecipient === 0}
          color='error'
          variant='dot'
          overlap='circular'
          badgeContent={<BadgeContentSpan />}
          onClick={handleDropdownOpen}
          sx={{
            '& .MuiBadge-badge': { top: 4, right: 4, boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}` }
          }}
        >
          <ChatBubbleOutlineIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ pb: 0, mt: 4 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={(theme) => ({
          width: 400,
          [theme.breakpoints.down('sm')]: { width: 320 }
        })}>
          <Box sx={{ pt: 2, pb: 3, px: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 600 }}>{formatMessage({ id: 'support-chat' })}</Typography>
              <IconButton size='small' onClick={() => handleDropdownClose()}>
                <Icon icon='mdi:close' />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ m: 0, p: 0 }}>
            <ChatContent
              channel={channel}
              messages={messages}
              page={page}
              isDisabledPagination={isDisabledPagination}
              onNextPage={onNextPage}
            />
          </Box>
        </Box>
      </Popover>
    </Fragment>
  )
}

export default ChatDropdown
