// ** React Imports
import { ReactNode } from 'react'

// ** MUI core Imports
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { Divider } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

interface IProps {
  open: boolean
  onClose: (evt: any) => void
  children: ReactNode | ReactNode[]
  title?: string | ReactNode | ReactNode[]
  description?: string | ReactNode | ReactNode[]
  autoHeight?: boolean
}

const ModalOne = ({ open, onClose, children, title, description, autoHeight = false }: IProps) => {
  const theme = useTheme()

  return (
    <Modal open={open} onClose={onClose} sx={{ backdropFilter: `blur(${theme.spacing(1)})` }} closeAfterTransition>
      <Box sx={{ m: '0 auto', maxWidth: 768, height: 1, overflow: 'auto', outline: 'none' }}>
        <Box sx={{ width: 1, minHeight: 1, height: 'auto', display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 1,
              height: autoHeight ? 'auto' : 1,
              maxHeight: 'auto',
              bgcolor: 'background.paper',
              borderRadius: 0.5
            }}
          >
            <Box
              sx={{
                p: theme.spacing(0, 6),
                height: 64,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant='h6' mr='auto'>
                {title}
              </Typography>
              <IconButton size='small' sx={{ p: 1 }} onClick={onClose}>
                <Icon icon='mdi:close' fontSize={24} />
              </IconButton>
            </Box>
            <Divider sx={{ '&.MuiDivider-root': { mt: 0, mb: 0 } }} />
            {description && (
              <Typography sx={{ m: theme.spacing(2, 6), maxWidth: 560, whiteSpace: 'pre-wrap' }} variant='subtitle2'>
                {description}
              </Typography>
            )}
            <Box
              sx={{
                height: 'calc(100% - 65px)',
                overflow: 'auto',
                p: theme.spacing(4, 6)
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalOne
